<template>
  <div class="elite-tabs-wrapper-content">
    <!--<div class="custom-header">
      <div class="row">
          <div class="col-12 text-right">
              <base-button
                  @click="printAccountStatus()"
                  type="button"
                  class="elite-button btn-success text-right"
              >
                <span class="btn-inner--icon mr-1">
                  <i class="fa fa-file"></i>
                </span>
                <span class="btn-inner--text">
                  {{ $t("CONTACTS.PRINT_ACCOUNT_STATUS") }}
                </span>
              </base-button>
            </div>
        </div>
    </div>-->
    <h3 v-if="contact.company_name">{{ `${contact.company_name}` }}</h3>
    <h3 v-else>{{ `${contact.firstname} ${contact.lastname}` }}</h3>
    <div class="col-12">
      <dl class="row" v-if="contact?.contactable?.type">
        <dt class="col-sm-4">
          {{ contactableTypeName }}
        </dt>
        <dd class="col-sm-8">
          <router-link
            v-if="contact.organization"
            :to="$objectViewRoute(contact.contactable)"
          >
            {{ `${contactableName}` }}
          </router-link>
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.TITLE") }}</dt>
        <dd class="col-sm-8" v-if="contact?.title">
          {{ $t("COMMON." + contact?.title) }}
        </dd>
      </dl>

      <dl class="row" v-if="contact.firstname">
        <dt class="col-sm-4">{{ $t("COMMON.FIRSTNAME") }}</dt>
        <dd class="col-sm-8 text-capitalize">
          {{ contact.firstname }}
        </dd>
      </dl>

      <dl class="row" v-if="contact.lastname">
        <dt class="col-sm-4">{{ $t("COMMON.LASTNAME") }}</dt>
        <dd class="col-sm-8 text-uppercase">
          {{ contact.lastname ?? "" }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.COMPANY_NAME") }}</dt>
        <dd class="col-sm-8">
          {{ contact.company_name }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
        <dd class="col-sm-8">
          {{ contact.email }}
        </dd>
      </dl>

      <dl class="row" v-if="contact.phone">
        <dt class="col-sm-4">{{ $t("COMMON.PHONE") }}</dt>
        <dd class="col-sm-8">
          <phone-number
            :phoneNumber="contact.phone"
            :extension="contact.phone_extension"
            :type="contact.phone_type"
          />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.BIRTHDAY") }}</dt>
        <dd class="col-sm-8" v-if="contact.birthday">
          {{ $formatDate(contact.birthday, "dddd D MMMM YYYY") }}
        </dd>
      </dl>

      <dl class="row" v-if="contact.country">
        <dt class="col-sm-4">{{ $t("COMMON.COUNTRY") }}</dt>
        <dd class="col-sm-8">
          {{ contact.country }}
        </dd>
      </dl>

      <dl class="row" v-if="contact.state">
        <dt class="col-sm-4">{{ $t("COMMON.STATE") }}</dt>
        <dd class="col-sm-8 text-uppercase">
          {{ contact.state }}
        </dd>
      </dl>

      <dl class="row" v-if="contact.city">
        <dt class="col-sm-4">{{ $t("COMMON.CITY") }}</dt>
        <dd class="col-sm-8 text-uppercase">
          {{ contact.city }}
        </dd>
      </dl>

      <dl class="row" v-if="contact.zipcode">
        <dt class="col-sm-4">{{ $t("COMMON.ZIPCODE") }}</dt>
        <dd class="col-sm-8">
          {{ contact.zipcode }}
        </dd>
      </dl>

      <dl class="row" v-if="contact.address">
        <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
        <dd class="col-sm-8 text-uppercase">
          {{ contact.address }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
        <dd class="col-sm-8">
          <locations :locations="contact.allowedLocations" />
        </dd>
      </dl>

      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="contact.organization" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ contact.created_at | moment("LLLL") }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ contact.updated_at | moment("LLLL") }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert2";
import moment from "moment";

export default {
  name: "contact-view-global",

  props: ["contact"],

  components: { },

  data() {
    return {};
  },

  computed: {
    contactableName() {
      if (this.contact.contactable.type == "contacts") {
        return this.contact.contactable.company_name;
      } else if (this.contact.contactable.type == "customers") {
        return this.contact.contactable.customer_name;
      }
      return "N/A";
    },
    contactableTypeName() {
      if (this.contact.contactable.type == "contacts") {
        return this.$t("COMMON.SUPPLIER");
      } else if (this.contact.contactable.type == "customers") {
        return this.$t("COMMON.CUSTOMER");
      }
      return "N/A";
    },
  },

  created() {},

  methods: {
    contactUpdated() {
      this.$emit("contactUpdated", true);
    },
    /*async printAccountStatus() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      let params = {id: this.contact.id};
      try {
        const pdfResponse = await this.$store.dispatch(
          "contacts/printAccountStatus", params
        );

        const pdfPath = window.URL.createObjectURL(
          new Blob([pdfResponse.data], { type: "application/pdf" })
        );

        const downloadPdfLink = document.createElement("a");
        downloadPdfLink.href = pdfPath;
        downloadPdfLink.download =
          this.$t("CONTACTS.PRINT_ACCOUNT_STATUS_FILE_NAME_OF") +
          moment().format("DD/MM/YYYY_HH_mm_ss") +
          ".pdf";

        document.body.appendChild(downloadPdfLink);
        downloadPdfLink.click();
        document.body.removeChild(downloadPdfLink);

        swal.close();
      } catch (error) {
        console.log(error);
        swal.close();
        if (error?.response?.status === 404) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.NO_PROPERTY_FOUND"),
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
        }
      }
    },*/
  },

  mounted() {},

  watch: {
    contact(contact) {},
  },
};
</script>
