<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("CONTACTS.CONTACTS_LIST") }}</h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openContactCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_CONTACTS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("CONTACTS.ADD_CONTACT")
              }}</span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_CONTACTS)"
              :objectType="'contacts'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <contact-list-table
        @onViewContact="openContactViewModal"
        @onEditContact="openContactEditModal"
        @onDeleteContact="deleteContact"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewContactModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewContactModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'CONTACT_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("CONTACTS.VIEW_CONTACT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openContact"
                :objectType="'contacts'"
                :objectId="openContact.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="edit" @click="openContactEditModal(openContact)">
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button class="edit" @click="openAccountStatusModal(openContact)">
                  <i class="fal fa-file"></i>
                  <span>{{ $t("CONTACTS.ACCOUNT_STATUS") }}</span>
                </button>
                <button class="delete" @click="deleteContact(openContact)">
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeContactModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-contact-component
              v-if="openContact"
              :contactId="openContact.id"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditContactModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditContactModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'CONTACT'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("CONTACTS.EDIT_CONTACT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="view" @click="openContactViewModal(openContact)">
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeContactModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-contact-component
              v-if="openContact"
              :contactId="openContact.id"
              @onViewContact="openContactViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddContactModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddContactModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'CONTACT'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("CONTACTS.ADD_CONTACT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeContactModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-contact-component @onViewContact="openContactViewModal" />
          </div>
        </div>
      </div>
      <modal :show="showAccountStatusModal" modal-classes="modal-secondary" size="sm">
        <div class="modal-header">
          <h3 class="text-center">
            {{ $t("CONTACTS.ACCOUNT_STATUS") }}
          </h3>
          <button
              type="button"
              class="btn btn-link ml-auto"
              @click="closeAccountStatusModal"
            >
              <i class="fa fa-times"></i>
          </button>
        </div>
        
        <div class="form-wrapper">
          <base-input
            :label="`${$t('PROPERTIES_PAYMENTS.EMAIL')}`"
            :placeholder="$t('PROPERTIES_PAYMENTS.EMAIL')"
            v-model="dataAccountStatus.email"
            @change="onFormChanged()"
          />
        </div>
        <div class="text-center">
          <base-button
              @click="printAccountStatus"
              type="button"
              class="elite-button btn-success text-right"
          >
            <span class="btn-inner--icon mr-1">
              <i class="fa fa-print"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("CONTACTS.PRINT_ACCOUNT_STATUS") }}
            </span>
          </base-button>
          <base-button
              @click="sendMailAccountStatus"
              type="button"
              class="elite-button btn-success text-right"
          >
            <span class="btn-inner--icon mr-1">
              <i class="fa fa-envelope"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("CONTACTS.SEND_ACCOUNT_STATUS_FILE_NAME_OF") }}
            </span>
          </base-button>
        </div>
      </modal>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import ContactListTable from "./partials/ContactListTable.vue";
import EditContactComponent from "./components/EditContactComponent.vue";
import AddContactComponent from "./components/AddContactComponent.vue";
import ViewContactComponent from "./components/ViewContactComponent.vue";
import moment from "moment";

export default {
  layout: "DashboardLayout",

  components: {
    ContactListTable,
    NotificationSubscription,
    EditContactComponent,
    AddContactComponent,
    ViewContactComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const contactId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewContactModalOpened = false;
    let isEditContactModalOpened = false;
    let isAddContactModalOpened = false;
    let openContact = null;
    if (contactId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewContactModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditContactModalOpened = true;
      }
      openContact = { id: contactId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddContactModalOpened = true;
    }
    return {
      isViewContactModalOpened: isViewContactModalOpened,
      isEditContactModalOpened: isEditContactModalOpened,
      isAddContactModalOpened: isAddContactModalOpened,
      openContact: openContact,
      renderKey: 1,
      showAccountStatusModal: false,
      dataAccountStatus: {
        id: null,
        email: "",
      },
    };
  },
  methods: {
    openContactCreateModal() {
      this.closeContactModal();
      this.isAddContactModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Contacts",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openContactViewModal(contact, reRender = false) {
      this.closeContactModal();
      this.openContact = contact;
      this.isViewContactModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Contacts",
          query: { id: this.openContact.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openContactEditModal(contact) {
      this.closeContactModal();
      this.openContact = contact;
      this.isEditContactModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Contacts",
          query: { id: this.openContact.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeContactModal() {
      this.isAddContactModalOpened = false;
      this.isViewContactModalOpened = false;
      this.isEditContactModalOpened = false;
      this.openContact = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Contacts",
          query: {},
        }).href
      );
    },

    async deleteContact(contact) {
      const confirmation = await swal.fire({
        title: this.$t("CONTACTS.DELETE_THIS_CONTACT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("contacts/destroy", contact.id);
          this.renderKey++;
          this.closeContactModal();
          this.$notify({
            type: "success",
            message: this.$t("CONTACTS.CONTACT_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async openAccountStatusModal(){
      this.showAccountStatusModal = true;
    },
    closeAccountStatusModal(){
      this.showAccountStatusModal = false;
    },
    async sendMailAccountStatus(){
      this.dataAccountStatus.id = this.openContact.id;
      if(this.dataAccountStatus.email == ""){
        this.$notify({
          type: "danger",
          message: this.$t("PROPERTIES_PAYMENTS.EMPTY_EMAIL"),
        });
        return;
      }
      const confirmation = await swal.fire({
        text: this.$t("CONTACTS.SEND_ACCOUNT_STATUS_FILE_NAME_OF_QUESTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          this.showAccountStatusModal = false;
          swal.fire({
            title: `Chargement`,
            onBeforeOpen: () => {
              swal.showLoading();
            },
          });
          await this.$store.dispatch("contacts/sendMailAccountStatus", this.dataAccountStatus);
          this.renderKey++;
          swal.close();
          this.$notify({
            type: "success",
            message: this.$t("CONTACTS.ACCOUNT_STATUS_FILE_SENDED"),
          });
        }
      } catch (error) {
        this.showAccountStatusModal = false;
        swal.close();
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    async printAccountStatus() {
      swal.fire({
        title: `Chargement`,
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      let params = {id: this.openContact.id};
      try {
        const pdfResponse = await this.$store.dispatch(
          "contacts/printAccountStatus", params
        );

        const pdfPath = window.URL.createObjectURL(
          new Blob([pdfResponse.data], { type: "application/pdf" })
        );

        const downloadPdfLink = document.createElement("a");
        downloadPdfLink.href = pdfPath;
        downloadPdfLink.download =
          this.$t("CONTACTS.PRINT_ACCOUNT_STATUS_FILE_NAME_OF") +
          moment().format("DD/MM/YYYY_HH_mm_ss") +
          ".pdf";

        document.body.appendChild(downloadPdfLink);
        downloadPdfLink.click();
        document.body.removeChild(downloadPdfLink);

        swal.close();
      } catch (error) {
        console.log(error);
        swal.close();
        if (error?.response?.status === 404) {
          this.$notify({
            type: "danger",
            message: this.$t("ERRORS.NO_PROPERTY_FOUND"),
          });
        } else {
          this.$notify({
            type: "danger",
            message: "Oops, something went wrong!",
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .content-btns-modal{
    text-align: center !important;
  }
  .content-btns-modal a{
    margin-right: 20px;
  }
  .modal-header{
    margin-top: -43px;
    margin-right: -34px;
    margin-bottom: 0px;
    text-align: center;
  }
  .modal-header h3{
    margin-top: 10px;
  }
</style>